import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import styled from 'styled-components'

import Back from '../../components/caseStudies/back'
import BrandBox from '../../components/caseStudies/brandBox'
import ContentWrapper from '../../components/share/contentWrapper'
import Details from '../../components/caseStudies/details'
import Header from '../../components/caseStudies/header'
import IntroRow from '../../components/share/introRow'
import Layout from '../../components/layout'
import Quote from '../../components/share/quote'
import SpeakToTeam from '../../components/share/speakToTeam'
import TypeFormButton from '../../components/share/typeFormButton'

import belleIcon from '../../images/brandLogos/belleProperty.svg'

const query = graphql`
  query {
    header: file(relativePath: { eq: "caseStudies/header-belle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    agentImage: file(relativePath: { eq: "brandAgentIcon/kristy_salmon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const BellProperty = props => {
  return (
    <Layout>
      <StaticQuery
        query={query}
        render={data => (
          <Fragment>
            <Header
              background={data.header.childImageSharp.fluid}
              year="2000"
              url="belleproperty.com.au"
              brandName="Belle Property Group"
              icon={belleIcon}
              overlayColor="linear-gradient(121.47deg, rgba(52, 112, 94, 0.7) 17.84%, rgba(67, 148, 124, 0.7) 84.19%)"
              content={
                <Fragment>
                  From humble beginnings in the year 2000, explosive growth
                  across the eastern shores of Australia and all the way up into
                  the Northern Territory on the back of a familiar and household
                  name and magazine partnership.
                </Fragment>
              }
            />

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Details
                sizes={['National', 'Boutique']}
                integrations={['Agentbox/Xenu', 'Printers', 'Newspapers']}
                customTitle="# OF SALES IN 2018"
                customData={['6,000+']}
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Quote
                descStyle={{ width: '100%' }}
                desc={`“Seriously, you guys rock! And we are so happy to have come across. As they say, if only we have done this earlier. :)”`}
              />
              <IntroRow
                descWidth="85%"
                title="A tight migration deadline, cut in half and still the most successful and well received operational change for the brand in its history."
                content={
                  <Fragment>
                    <Paragraph>
                      A boutique approach to national franchise real estate has
                      helped Belle tap into an extensive network with a unique
                      approach to full service real estate services. Focused on
                      higher end markets from simple studios to multi-million
                      dollar homes their commitment to professionalism and
                      attention to detail meant they needed to know and trust in
                      a marketing platform that could provide a custom tailored
                      and considerably complex approach.
                    </Paragraph>
                    <Paragraph>
                      Our onboarding, template and support teams worked closely
                      over the course of just 3 months to migrate their entire
                      90 office group, including thousands of templates, unique
                      challenges and the refining of an already prestigious
                      brand to create a new brand style that helped move them
                      forward, without completely re-inventing the wheel.
                    </Paragraph>
                  </Fragment>
                }
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Quote
                desc={`“You guys know how to make us feel calm when we hit the go button. I must say this as it’s one of your best team qualities - everything felt easy and under control.”`}
                descStyle={{ width: '100%' }}
                agentImage={data.agentImage.childImageSharp.fluid}
                agentName="Kristy Salmon"
                title="Head of Operations"
                agencyName="BelleProperty"
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <IntroRow
                descWidth="75%"
                content={
                  <Fragment>
                    <Paragraph>
                      Across timezones, holiday periods and the training of the
                      entire group we successfully launched the entire brand to
                      an entirely positive experience for the entire group.
                    </Paragraph>
                    <Paragraph>
                      No migration is without it’s teething issues, but our
                      abilities shone through with fast, reactive and proactive
                      development and support that helped identify potential
                      costly mistakes before they occurs and quickly address and
                      solve unforeseen circumstances that arised.
                    </Paragraph>
                    <Paragraph>
                      In the settling dust, Belle was provided with a platform
                      that has massively impacted their ability to provide the
                      prestige quality service and attention to detail they were
                      already known for, at a much larger scale than previously
                      thought possible.
                    </Paragraph>

                    <Paragraph>
                      Get business changing software to scale up your future.{' '}
                      <TypeFormButton
                        text="Join the team at Realhub today."
                        plain
                      />
                    </Paragraph>
                  </Fragment>
                }
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Quote
                descStyle={{ width: '100%' }}
                desc={
                  <Fragment>
                    “Everyone including myself has been{' '}
                    <strong>over the moon </strong>
                    how smoothly everything has gone, your{' '}
                    <strong>communication</strong>, ease and flexibility and
                    just getting to know everyone up to this point has been
                    amazing.”
                  </Fragment>
                }
              />
            </ContentWrapper>

            <ContentWrapper>
              <SpeakToTeam
                uid="WpgxlK"
                avatar="https://labs-assets.typeform.com/cui/avatars/WpgxlK/cde0f362a9e74bff7bfc.png"
              />
            </ContentWrapper>
            <ContentWrapper style={{ margin: '40px auto' }}>
              <Back />
              <Flex>
                <BrandBox brandName="Cunninghams" />
                <BrandBox brandName="Harris" />
                <BrandBox brandName="LJHooker" />
              </Flex>
            </ContentWrapper>
          </Fragment>
        )}
      />
    </Layout>
  )
}

export default BellProperty

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
`

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
`
